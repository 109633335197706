
import { useNavigate } from "react-router-dom"
import { scrollTo } from "../../utils/helpers"
import { Row } from "../../components/common/Row"
import { Column } from "../../components/common/Column"

import { Container } from "../../components/common/Container"
import { Image } from "../../components/common/Image"
import { Link } from "../../components/common/Link"
import { Space } from "../../components/common/Space"


export const Footer = () => {
    const navigate = useNavigate()

    return (
        <footer>
            <Container>

                <Row>
                    <Column alignItems='center'>
                        <Image
                            name='logo.svg'
                            alt={'logo'}
                            id='footer-logo'
                            onClick={async () => {
                                await navigate('/')
                                scrollTo('', 'start')
                            }}
                        />
                    </Column>

                </Row>
                <Row responsive>
                    <Column justifyContent={'center'}>
                        <span>Quick Credit Schweiz GmbH </span>
                        <Space size={.5} desktop/>
                        <span>Baarermatte 1, <br/> 6340 Baar</span>    
                        <Space size={5} mobile />                    
                    </Column>
                    <Column>                        
                        <ul>
                            <li><Link href='/' target={'about'}>Über Uns</Link></li>    
                            <li><Link href='/' target={'faq'}>FAQ</Link></li>                                                                                
                        </ul>
                    </Column>
                    <Column>                        
                        <ul>                            
                            <li><Link href='/datenschutz'>Datenschutz</Link></li>
                            <li><Link href='/impressum'>Impressum</Link></li>                            
                        </ul>
                    </Column>
                    <Column>
                        <Space size={10} mobile />   
                        <span><b>Folge uns:</b></span>
                        <Row alignItems={'flex-start'} className={'social-icons'}>
                            <Column flex={{ desktop: .2, mobile: 1 }}>
                                <a className="link" aria-label="facebook" href='https://www.facebook.com/profile.php?id=100082507667618&locale=de_DE'>
                                    <Image name={'facebook.svg'} width={'30px'} height={'30px'} />
                                </a>
                            </Column>

                            <Column flex={{ desktop: .2, mobile: 1 }}>
                                <a className="link" aria-label="instagram" href='https://www.instagram.com/quick_credit_ch/'>
                                    <Image name={'instagram.svg'} width={'30px'} height={'30px'} />
                                </a>
                            </Column>
                        </Row>
                    </Column>
                </Row>
                <hr />
                <Row responsive>
                    <Column>
                        <Row className={'phone-row'}>
                            <Image id={'contact-icon'} name={'phone.svg'} width={'20px'} height={'20px'} />
                            <span> +41 76 442 89 25</span>
                        </Row>

                    </Column>
                    <Space size={3} mobile />   
                    <Column alignItems={'flex-end'} justifyContent={'center'} flex={{ desktop: 3, mobile: 1 }}>
                        <span>© Quick Credit Schweiz</span>
                    </Column>
                </Row>


            </Container>
        </footer>
    )
}