export const preloadImagesStart = () => ({
    type: 'PRELOAD_IMAGES_START',
});

export const preloadImagesSuccess = (images) => ({
    type: 'PRELOAD_IMAGES_SUCCESS',
    payload: images,
});

export const preloadImagesFailure = (error) => ({
    type: 'PRELOAD_IMAGES_FAILURE',
    payload: error,
});
