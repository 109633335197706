import { LoadingOutlined } from '@ant-design/icons'

import './index.scss'

export const Loading = () => {
    return (
        <div className='loading-container'>            
            <LoadingOutlined style={{ fontSize: '82px', color: 'white' }} />            
        </div>
    )
}