import { Container } from "../../components/common/Container";
import { Contact } from "../../sections/Contact";

export const ContactPage = () => {
  return (
    <>
      {/* ContactPage page content */}
      <Contact />
    </>
  );
}