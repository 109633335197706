import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { Section } from "../../components/common/Section"
import { Row } from "../../components/common/Row"
import { Image } from "../../components/common/Image"
import './index.scss'
import { OnTop } from "../../components/common/OnTop"
import { Space } from "../../components/common/Space"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './index.scss'

export const Achievments = () => {
    return (
        <Section id={'about'} background={'g_1'} color={'white'}>            
            <Container>
            <Row justifyContent={'center'}>
                <Column alignItems={'center'} flex={{desktop: .7}}>
                
                    <h2>Über <strong>Uns</strong><span className="dot">.</span></h2>   
                    <Space size={3} />                                     
                    <p style={{textAlign: 'center'}}>
                        Willkommen bei Quick Credit Schweiz - Ihrem Partner für <strong>schnelle</strong> und  <strong>professionelle</strong>  Kreditvermittlung! Wir bieten Ihnen die schnellsten Finanzlösungen und stehen Ihnen mit unserer Expertise jederzeit zur Seite.                          
                    </p>

                    <p><strong>Entdecken Sie, wie wir Ihre finanziellen Anliegen effizient und zuverlässig lösen können.</strong></p>

                </Column>

                </Row>
                <hr style={{opacity: .2}}/>

                <Space size={10} />

                <Row responsive>
                    <Column alignItems={'center'}>
                        <Image name='achievment-contracts.svg' height={'50%'} alt={'antrage'}/>

                        <OnTop left={'50%'} top='50%'>
                            <span className="achievment-value">
                                1377
                            </span>
                        </OnTop>
                        <Space size={5} />

                        <span className="achievment-title">Abgeschlossene Verträge</span>
                    </Column>
                    <Column flex={{ desktop: 0.5 }} />

                    <Column alignItems={'center'}>
                        <Image name='achievment-clients.svg' height={'50%'} alt={'kliente'}/>

                        <OnTop left={'50%'} top='50%'>
                            <span className="achievment-value">
                                53M+
                            </span>
                        </OnTop>
                        <Space size={5} />

                        <span className="achievment-title">Ausbezahlt</span>
                    </Column>

                    <Column flex={{ desktop: 0.5 }} />

                    <Column alignItems={'center'}>
                        <Image name='achievment-trust.svg' height={'50%'} alt={'Vertrauen'}/>

                        <OnTop left={'50%'} top='50%'>
                            <span className="achievment-value">
                                47
                            </span>
                        </OnTop>

                        <Space size={5} />

                        <span className="achievment-title">Jahre Krediterfahrung</span>
                    </Column>
                </Row>

                
            </Container>

        </Section>
    )
}