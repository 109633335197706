import { Container } from "../../components/common/Container"

import './index.scss'

export const LoanDisclaimer = () => {
    return (
        <div id="loandisclaimer">
            <Container>
                <p>
                    Beispiel einer Berechnung: Darlehen CHF 10'000. Bei einem effektiven Jahreszinssatz zwischen 4.9 % und 11.95 % und einer Laufzeit von 12 Monaten ergeben sich Zinsen von insgesamt CHF 261.80 bis CHF 624.80. Laufzeit: 6-120 Monate; maximaler Jahreszinssatz (einschliesslich aller Kreditkosten) 11.95 %. Die Gewährung des Kredits ist untersagt, wenn sie zu einer Überschuldung des Verbrauchers führt. (Art. 3 UWG)
                </p>
            </Container>


        </div>
    )
}