import { useState } from "react"

import { Column } from "../../components/common/Column"
import { Accordion } from "../../components/common/Accordion"
import { Row } from "../../components/common/Row"
import { Section } from "../../components/common/Section"
import { Container } from "../../components/common/Container"

import './index.scss'
import { Space } from "../../components/common/Space"

export const FAQ = () => {
    const [expanded, setExpanded] = useState(false)

    return (
        <Section id='faq'>
            <Container>
                <Column>
                    <Row justifyContent={'center'}>
                        <h2><strong>Häufig</strong> gestellte <strong>Fragen</strong><span className="dot">.</span></h2>    
                    </Row>                    
                    <Space size={3} />
                    <Row responsive alignItems='flex-start'>
                        <Column>
                            <Accordion justifyHeading={'space-between'} heading='Warum bei uns Kredit beantragen?' i={0} expanded={expanded} setExpanded={setExpanded}>
                                <p>In unserem Unternehmen sind wir hier, um Ihnen zu helfen, wenn Sie diese am dringendsten benötigen. Wir verstehen, dass Hausbanken Ihre Bedürfnisse manchmal nicht erfüllen können, aber seien Sie versichert, dass unser Team über jahrelange Erfahrung und starke Partnerschaften mit einigen der renommiertesten Schweizer Banken verfügt. Auch in herausfordernden Situationen sind wir zuversichtlich, dass wir gemeinsam eine Lösung finden können, die für Sie am besten funktioniert. Warum lassen Sie sich also nicht heute von uns unterstützen?</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Welche Bedingungen muss ich für einen Kredit erfüllen?' i={1} expanded={expanded} setExpanded={setExpanded}>
                                <p>Um für einen Privatkredit in der Schweiz in Frage zu kommen, müssen Sie einige Grundvoraussetzungen erfüllen. Dazu gehören ein Alter zwischen 18 und 65 Jahren und entweder Schweizer Staatsbürger oder Inhaber einer Aufenthaltsbewilligung C, oder B, einer Grenzgängerbewilligung seit mindestens 3 Jahren, oder einer Legitimationskarte. Wenn Sie diese Kriterien erfüllen, können wir Sie bei der Beantragung eines Kredits unterstützen.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Welche Kosten entstehen durch die Bearbeitung?' i={2} expanded={expanded} setExpanded={setExpanded}>
                                <p>Die Kreditanfrage und Bearbeitung sind bei uns selbstverständlich kostenlos.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Was ist, wenn ich arbeitslos oder arbeitsunfähig werde?' i={3} expanded={expanded} setExpanded={setExpanded}>
                                <p>Sie haben die Möglichkeit, sich gegen eine Insolvenz wegen Arbeitsunfähigkeit, oder Arbeitslosigkeit durch den Abschluss einer Versicherung abzusichern. Diese Versicherung würde im Falle einer Insolvenz die Restschuld abdecken.</p>
                            </Accordion>


                        </Column>
                        <Column flex={{ desktop: 0.015 }} />
                        <Column>
                            <Accordion justifyHeading={'space-between'} heading='Hat mein Alter Einfluss auf die Kreditvergabe?' i={4} expanded={expanded} setExpanded={setExpanded}>
                                <p>Das Alter kann Ihre Kreditwürdigkeit beeinflussen, insbesondere wenn Sie unter 24 Jahre alt sind. Für junge Arbeitnehmer können strengere Anforderungen gelten, um eine Überschuldung zu verhindern, aber mit einer guten Vorbereitung ist es immer noch möglich, einen Kredit zu erhalten. In der Schweiz können Personen unter 18 Jahren oder über dem Rentenalter systematisch abgelehnt werden, mit Ausnahme einiger Fälle bis zum Alter von 70 Jahren.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Wie berechne ich den maximalen Höchstbetrag?' i={5} expanded={expanded} setExpanded={setExpanded}>
                                <p>Wir möchten sicherstellen, dass Sie Ihr Darlehen bequem zurückzahlen können, weshalb unser maximaler Darlehensbetrag auf der Grundlage Ihrer Rückzahlungsfähigkeit innerhalb von 36 Monaten berechnet wird. Dies gilt auch dann, wenn eine längere Rückzahlungsfrist vereinbart wurde, einschließlich der während dieser 36-Monats-Periode aufgelaufenen Zinsen. Um Ihren maximalen Kreditbetrag zu ermitteln, berechnen wir Ihr verfügbares monatliches Budget, indem wir Ihre Ausgaben von Ihren Einnahmen abziehen. Dieses monatliche Budget wird dann mit 36 multipliziert, um die maximale Kreditsumme zu erhalten. Zusammenfassend also: Maximaler Kreditbetrag = Verfügbares Monatsbudget x 36.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Das Scoring-Sytem, worum handelt es sich hierbei?' i={6} expanded={expanded} setExpanded={setExpanded}>
                                <p>Ihre Bonität ist ein wichtiger Faktor bei der Beurteilung Ihrer Kreditwürdigkeit, da sie Ihre allgemeine Kreditwürdigkeit widerspiegelt. Um Ihre Bewertung zu ermitteln, überprüft die Bank Ihr Zahlungsverhalten für Kredite, Fahrzeugleasing und Kreditkarten Bei dieser Bewertung wird Ihr Rechnungszahlungsverhalten mit Hilfe verschiedener Datenbanken analysiert. Darüber hinaus berücksichtigt die Bank die im Kreditcenter erfassten früheren Kreditablehnungen. Deshalb ist es wichtig, sich direkt bei der Bank zu bewerben, die am besten zu Ihren Bedürfnissen passt. Auf diese Weise können Sie Ihre Chancen auf eine Kreditzusage erhöhen und Ihre Bonität im Laufe der Zeit verbessern.</p>
                            </Accordion>
                            <Accordion justifyHeading={'space-between'} heading='Was ist eine Kreditversicherung?' i={7} expanded={expanded} setExpanded={setExpanded}>
                                <p>Eine Kreditversicherung schützt Kreditnehmer vor unvorhergesehenen Ereignissen wie Arbeitslosigkeit oder Krankheit, die die Rückzahlung des Kredits erschweren könnten.</p>
                            </Accordion>
                        </Column>
                    </Row>
                </Column>
            </Container>
        </Section>
    )
}