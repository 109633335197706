import { Column } from "../../components/common/Column";
import { Container } from "../../components/common/Container";
import { Section } from "../../components/common/Section";
import { Space } from "../../components/common/Space";
import { Row } from "../../components/common/Row";

export const ImpressumPage = () => {
    return (
        <Section>
            <Container>
                <Column>
                <Row justifyContent={'flex-start'}>
                    <h1>Impressum</h1>
                </Row>
                <Row justifyContent={'flex-start'}><span>Quick Credit Schweiz GmbH</span></Row>
                
                <Row justifyContent={'flex-start'}><span>Baarermatte 1, 6340 Baar</span></Row>
                
                <Space size={3} />
                <Row justifyContent={'flex-start'}><span>Handelsregister-Nummer CH-170-4022121-3</span></Row>
                
                <Row justifyContent={'flex-start'}><span>UID/MWST CHE-229.969.472</span></Row>
                
                </Column>               
            </Container>
        </Section>
    );
}

