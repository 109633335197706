import { Header } from "../../sections/Header"
import { FAQ } from "../../sections/FAQ"
import { ServiceChoise } from "../../sections/ServiceChoice";
import { Achievments } from "../../sections/Achievments";
import { Sequence } from "../../sections/Sequence";
import { LoanDisclaimer } from "../../sections/LoanDisclaimer";
import { Contact } from "../../sections/Contact";
import { Immobilien } from "../../sections/immobilien";

export const HomePage = () => {
	return (
		<>
			<Header />
			<ServiceChoise />					
			<Achievments />			
			<Sequence />
			<FAQ />
			<Immobilien />
			<Contact />			
			<iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2711.8016073965637!2d8.524136596988354!3d47.18132079677693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aaa4d86590ac5%3A0x7c92c97dbdac3666!2sBaarermatte%201%2C%206340%20Baar%2C%20Switzerland!5e0!3m2!1sen!2sus!4v1715192114768!5m2!1sen!2sus" className="map" style={{border: 'none'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>						
			<LoanDisclaimer />						
		</>
	);
}