import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const Image = ({
    name,
    alt,
    width,
    height,
    className,
    id,
    onClick,
}) => {
    const preloadedImages = useSelector(state => state.preload.images);
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        if (preloadedImages[name]) {
            setImageSrc(preloadedImages[name]);
        } else {
            import(`../../../assets/images/${name}`).then(imageModule => {
                setImageSrc(imageModule.default);
            }).catch(error => {
                console.error('Error importing image', error);
            });
        }
    }, [name, preloadedImages]);

    if (imageSrc) {
        return (
            <div 
                className={className} 
                id={id}
                onClick={onClick} 
                style={{width: width, height: height,}}           
            > 
                <img 
                    src={imageSrc}
                    alt={alt || 'image'}              
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        display: 'flex'
                    }}
                />
            </div>
        );    
    }    
};
