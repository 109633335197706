import { preloadImagesStart, preloadImagesSuccess, preloadImagesFailure } from '../redux/actions/preloadActions';
import { imageNames } from './imageList'; // Adjust the import path

export const preloadImages = () => {
    return async (dispatch) => {
        dispatch(preloadImagesStart());

        const loadImage = (name) => {
            return new Promise((resolve, reject) => {
                import(`../assets/images/${name}`)
                    .then((imageModule) => {
                        const img = new Image();
                        img.src = imageModule.default;
                        img.onload = () => resolve({ name, src: img.src });
                        img.onerror = () => reject(new Error(`Failed to load image: ${name}`));
                    })
                    .catch(reject);
            });
        };

        try {
            const loadedImages = await Promise.all(imageNames.map(loadImage));
            const images = loadedImages.reduce((acc, { name, src }) => {
                acc[name] = src;
                return acc;
            }, {});
            dispatch(preloadImagesSuccess(images));
        } catch (error) {
            dispatch(preloadImagesFailure(error));
        }
    };
};
