import { Section } from "../../components/common/Section"
import { Row } from "../../components/common/Row"
import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { ContentBlock } from "../../components/common/ContentBlock"
import { Space } from "../../components/common/Space"
import { Image } from "../../components/common/Image"
import { OnTop } from "../../components/common/OnTop"

import './index.scss'


export const Sequence = () => {
    return (
        <Section id={'sequence'} background={'c_gray'}>
            <Container>
                <Column>
                    <Row justifyContent={'center'}>
                        <h2><strong>Ihr Antrag</strong> & nächste Schritte<span className="dot">.</span></h2>
                    </Row>
                    <p>
                    Die sorgfältige Vorbereitung Ihrer Bewerbungsunterlagen ist von entscheidender Bedeutung. Es kann nützlich sein, hierbei Unterstützung in Anspruch zu nehmen. Bei Quick Credit haben wir spezifische Maßnahmen entwickelt, um Ihre Erfolgschancen auf eine positive Rückmeldung zu erhöhen.
                    </p>
                </Column>

                <Space size={10} />

                <Row responsive justifyContent={'space-between'}>
                    <Column alignItems={'center'} className={'icon-container'}>
                        <ContentBlock background={'g_3'} >
                            <Image name={'sequence-contract.svg'} height={'100px'}/>

                            <OnTop bottom={'-70px'} left={'50%'}>
                                <span className="sequence-number"> 1 </span>
                            </OnTop>
                        </ContentBlock>
                        <Space size={3} />
                        <p className="step-decription">
                            Ausfüllen des Antrags
                        </p>
                    </Column>

                    <Column flex={{ desktop: .1 }} />

                    <Column alignItems={'center'} className={'icon-container'}>
                        <ContentBlock background={'g_3'}>
                            <Image name={'sequence-analysis.svg'} height={'100px'}/>
                            <OnTop bottom={'-70px'} left={'50%'}>
                                <span className="sequence-number"> 2 </span>
                            </OnTop>
                        </ContentBlock>

                        <Space size={3} />
                        <p className="step-decription">
                            Wir kontaktieren Sie telefonisch, am selben Tag
                        </p>
                    </Column>

                    <Column flex={{ desktop: .1 }} />

                    <Column alignItems={'center'} className={'icon-container'}>
                        <ContentBlock background={'g_3'}>
                            <Image name={'sequence-partners.svg'} height={'100px'} />
                            <OnTop bottom={'-70px'} left={'50%'}>
                                <span className="sequence-number"> 3 </span>
                            </OnTop>
                        </ContentBlock>
                        <Space size={3} />
                        <p className="step-decription">
                            Analyse Ihrer Kreditanfrage

                        </p>
                    </Column>

                    <Column flex={{ desktop: .1 }} />

                    <Column alignItems={'center'} className={'icon-container'}>
                        <ContentBlock background={'g_3'}>
                            <Image name={'sequence-wait.svg'} height={'100px'} />
                            <OnTop bottom={'-70px'} left={'50%'}>
                                <span className="sequence-number"> 4 </span>
                            </OnTop>
                        </ContentBlock>
                        <Space size={3} />
                        <p className="step-decription">
                            Es wird ein maßgeschneidertes Angebot verschickt

                        </p>
                    </Column>

                    <Column flex={{ desktop: .1 }} />

                    <Column alignItems={'center'} className={'icon-container'}>
                        <ContentBlock background={'g_3'}>
                            <Image name={'sequence-payment.svg'} height={'100px'} />
                            <OnTop bottom={'-70px'} left={'50%'}>
                                <span className="sequence-number"> 5 </span>
                            </OnTop>
                        </ContentBlock>
                        <Space size={3} />
                        <p className="step-decription">
                            Das Geld wird auf Ihr Konto überwiesen
                        </p>
                    </Column>
                </Row>
            </Container>


        </Section>
    )
}