import { Section } from "../../components/common/Section"
import { Row } from "../../components/common/Row"
import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { Image } from "../../components/common/Image"
import { Space } from "../../components/common/Space"
import { ContentBlock } from "../../components/common/ContentBlock"
import { Link } from "../../components/common/Link"
import './index.scss'

export const ServiceChoise = () => {    
    return (
        <Section id={'service-choice'} background={'c_gray'}>

            <Container>
                <Row justifyContent={'flex-start'}>
                    <h2>Persönlicher <br /><strong>Kredit</strong><span className="dot">.</span></h2>
                </Row>
                <Row responsive>
                    <Column>
                        <Row>
                            <Column flex={{ desktop: 0.2, mobile: 0.3 }} alignItems={'flex-start'} justifyContent={'center'}>
                                <div className="icon-container">
                                    <Image name={'kredit-money.svg'} alt={'zins'}/>
                                </div>
                            </Column>
                            <Column flex={{ desktop: 0.01, mobile: 0.1 }} />
                            <Column>
                                <h3>Kredit ab 4.9% Zins</h3>
                                <p>
                                Die Zinssätze variieren zwischen 4.9% und 11.95%, abhängig von verschiedenen Faktoren. Die Kreditkosten werden Ihnen transparent und übersichtlich erklärt.
                                </p>

                            </Column>
                        </Row>
                        <Row>
                            <Column flex={{ desktop: 0.2, mobile: 0.3 }} alignItems={'flex-start'} justifyContent={'center'}>
                                <div className="icon-container">
                                    <Image name={'kredit-time.svg'} alt={'zeit'}/>
                                </div>
                            </Column>
                            <Column flex={{ desktop: 0.01, mobile: 0.1 }} />
                            <Column>
                                <h3>Bis zu 120 Monate Laufzeit</h3>
                                <p>
                                Sie können eine Laufzeit von bis zu 120 Monaten wählen, um Ihre monatlichen Raten optimal an Ihre finanzielle Situation anzupassen. So behalten Sie stets die volle Kontrolle über Ihre Ausgaben.
                                </p>

                            </Column>
                        </Row>

                    </Column>

                    <Column flex={{ desktop: 0.1 }}>

                    </Column>
                    <Column>

                        <Row>
                            <Column flex={{ desktop: 0.2, mobile: 0.3 }} alignItems={'flex-start'} justifyContent={'center'}>
                                <div className="icon-container">
                                    <Image name={'kredit-fast.svg'} alt={'schnell'}/>
                                </div>
                            </Column>
                            <Column flex={{ desktop: 0.01, mobile: 0.1 }} />
                            <Column>
                                <h3>Kredite über 80‘000.- Sofortauszahlung</h3>
                                <p>
                                Für Kreditsummen über 80.000 CHF garantieren wir eine sofortige Auszahlung. So haben Sie schnell Zugriff auf die benötigten Mittel und können Ihre Pläne ohne Verzögerung realisieren.
                                </p>

                            </Column>
                        </Row>
                        <Row>
                            <Column flex={{ desktop: 0.2, mobile: 0.3 }} alignItems={'flex-start'} justifyContent={'center'}>
                                <div className="icon-container">
                                    <Image name={'kredit-increase.svg'} alt={'zunahme'}/>
                                </div>
                            </Column>
                            <Column flex={{ desktop: 0.01, mobile: 0.1 }} />
                            <Column>
                                <h3>Kostenlose Zinssenkung</h3>
                                <p>
                                Profitieren Sie von der Möglichkeit einer kostenlosen Zinssenkung. Dies hilft Ihnen, die Gesamtkosten Ihres Kredits zu reduzieren und langfristig Geld zu sparen.
                                </p>
                            </Column>
                        </Row>
                    </Column>
                </Row>

                <Space size={10} DESKTOP />
                <span className="option-header" >Holen Sie sich einen Kredit für Ihren Bedarf</span>
                <hr />
                <Space size={3} DESKTOP />
                <Row className={'options'}>
                    <Column alignItems={'center'}>
                        <Link href='/immobilien-kredit'>
                            <ContentBlock className={'loan-for'}>
                                <Image name='immobilien.webp' height={'200%'} alt={'immobilien'}/>
                                <h4>Immobilien</h4>
                            </ContentBlock>
                        </Link>


                    </Column>
                    <Column alignItems={'center'}>
                        <Link href='/bildung-kredit'>
                            <ContentBlock className={'loan-for'}>
                                <Image name='bildung.webp' height={'200%'} alt={'bildung'}/>
                                <h4>Bildung</h4>
                            </ContentBlock>
                        </Link>

                    </Column>
                    <Column alignItems={'center'}>
                        <Link href='/renovation-kredit'>
                            <ContentBlock className={'loan-for'}>
                                <Image name='interior.webp' height={'200%'} alt={'interior'}/>
                                <h4>Renovation</h4>
                            </ContentBlock>
                        </Link>
                    </Column>
                    <Column alignItems={'center'}>
                        <Link href='/reisen-kredit'>
                            <ContentBlock className={'loan-for'}>
                                <Image name='vacation.webp' height={'200%'} alt={'reisen'}/>
                                <h4>Reisen</h4>
                            </ContentBlock>
                        </Link>

                    </Column>
                    <Column alignItems={'center'}>
                        <Link href='/elektronik-kredit'>
                            <ContentBlock className={'loan-for'}>
                                <Image name='gadgets.webp' height={'200%'} alt={'elektronik'}/>
                                <h4>Elektronik</h4>
                            </ContentBlock>
                        </Link>

                    </Column>
                    <Column alignItems={'center'}>
                        <Link href='/fahrzeuge-kredit'>
                            <ContentBlock className={'loan-for'}>
                                <Image name='cars.webp' height={'200%'} alt={'fahrzeuge'}/>
                                <h4>Fahrzeuge</h4>
                            </ContentBlock>
                        </Link>
                    </Column>
                </Row>
            </Container>
        </Section>
    )
}