import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { HomePage } from './pages/HomePage'
import { LoginPage } from './pages/LoginPage'
import { ContactPage } from './pages/ContactPage'
import { LandingPage } from './pages/LandingPage'
import { DatenschutzPage } from './pages/DatenschutzPage'
import { ImpressumPage } from './pages/ImpressumPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { ApplicationPage } from './pages/ApplicationPage'
import { Footer } from './sections/Footer';
import { Loading } from './sections/Loading';
import { ApplicationFinish } from './pages/ApplicationFinish';

import { Navbar } from './sections/Navbar'

import './App.scss'

import { useDispatch, useSelector } from 'react-redux';
import { preloadImages } from './utils/preloadImages';

const App = () => {		
	const dispatch = useDispatch()	
	const isPreloading = useSelector(state => state.preload.isPreloading)
	const error = useSelector(state => state.preload.error)

	useEffect(() => {
		dispatch(preloadImages())
	}, [dispatch])

	if (isPreloading) {
        return <Loading />; // Show a loading indicator while images are preloading
    }

    if (error) {
        return <div>Error loading images: {error.message}</div>; // Handle loading errors
    }
	
	
	return (
		<Router>			
			<div className="App">				
				<Navbar items={[
					{
						items: [							
							{
								title: 'Immobilien',
								type: 'link',
								href: 'https://immobilien.quick-credit.ch/',								
							},
							{
								title: 'Kontakt',
								type: 'pointer',
								url: '/',
								section: 'contact'
							},
							{
								title: 'Über Uns',
								type: 'pointer',
								url: '/',
								section: 'about'
							},
							{
								title: 'FAQ',
								type: 'pointer',
								url: '/',
								section: 'faq'
							},
						]
					},
					{
						items: [
							{
								title: 'Kreditantrag',
								type: 'action-link',
								url: '/application',	
								buttonType: 'secondary',
								icon: 'application_white.svg'					
							},							
						]


					}

				]} />
				
					<Routes>
						<Route exact path="/" element={<HomePage />} />

						<Route exact path="/immobilien-kredit" element={<HomePage />} />
						<Route exact path="/bildung-kredit" element={<HomePage />} />
						<Route exact path="/renovation-kredit" element={<HomePage />} />
						<Route exact path="/reisen-kredit" element={<HomePage />} />
						<Route exact path="/elektronik-kredit" element={<HomePage />} />
						<Route exact path="/fahrzeuge-kredit" element={<HomePage />} />						

						<Route path="/login" element={<LoginPage />} />
						<Route path="/contact" element={<ContactPage />} />
						<Route path="/landing" element={<LandingPage />} />
						<Route path="/impressum" element={<ImpressumPage />} />
						<Route path="/datenschutz" element={<DatenschutzPage />} />							
						<Route path="/application" element={<ApplicationPage />} />		
						<Route path="/application" element={<ApplicationPage />} />								
						<Route path="/antrag_erfolgreich" element={<ApplicationFinish />} />			
						{/* Add more routes as needed */}
						{/* 404 route */}
						<Route path='*' element={<NotFoundPage />} />
					</Routes>
				<div id='footer-container'>
					<Footer />
				</div>


				{ /*
			<Navbar />
			<Header />
			<About />
			<Contact />
			<Footer />
		*/ }
			</div>
		</Router>

	)
}

export default App
