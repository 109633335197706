import { Row } from "../Row"
import { Image } from "../Image"

export const Button = ({bType, onClick, className = '', id, children, type, color, icon, ariaLabel}) => {
    return (
        <button type={bType} style={{color: color}} aria-label={ariaLabel || children}  id={id} onClick={onClick} className={`button-${type} ${className}`}>
            <Row justifyContent={'center'} alignItems={'center'}>
                {
                    icon && <Image alt={'button-icon'} name={icon} height={'25px'} width={'25px'} className={'button-icon'} />
                }
                
                {children}
            </Row>
            
        </button>
    )
}