import { Row } from "../../components/common/Row"
import { Column } from "../../components/common/Column"
import { Input } from "../../components/common/Input"
import { Section } from "../../components/common/Section"
import { Button } from "../../components/common/Button"
import { Space } from "../../components/common/Space"
import { Container } from "../../components/common/Container"
import { ContentBlock } from "../../components/common/ContentBlock"
import { useSelector } from "react-redux"
import { LoadingOutlined } from '@ant-design/icons'
import emailjs from '@emailjs/browser'


import './index.scss'
import { useRef } from "react"
import { useState } from "react"



export const Contact = () => {
    const ref = useRef()
    const [isMessageSending, setMessageSending] = useState(false)
    const [applicationSent, setApplicationSent] = useState(false) 

    const hiddenInputs = (
        <>
            <input name='contact-name' value={useSelector(state => state.data['contact-name']) || ''} readOnly style={{ display: 'none' }} />
            <input name='contact-lastname' value={useSelector(state => state.data['contact-lastname']) || ''} readOnly style={{ display: 'none' }} />
    
            <input name='contact-number' value={useSelector(state => state.data['contact-number']) || ''} readOnly style={{ display: 'none' }} />
            <input name='contact-email' value={useSelector(state => state.data['contact-email']) || ''} readOnly style={{ display: 'none' }} />
            <input name='contact-message' value={useSelector(state => state.data['contact-message']) || ''} readOnly style={{ display: 'none' }} />
        </>
    )
    
    const handleFormSubmit = (event) => {
        event.preventDefault()
        setApplicationSent(true)
        setMessageSending(true)
        emailjs.sendForm('service_rrdmzkw', 'template_7bqhaob', ref.current, 'VkO7FJ4laHj-xh2Dl')
            .then((result) => {
                console.log(result.text);
    
    
                setMessageSending(false)
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <Section id='contact' background={'c_gray'}>
            <Container>
                <Row responsive>
                    <Column justifyContent={'center'} alignItems={'flex-start'}>
                        <h2>
                            <strong>Haben</strong> Sie eine <strong>Frage?</strong>
                            
                        </h2>
                        
                        <Space size={1} />
                        <p>
                            Wir sind für Sie da, wenn Sie Fragen haben oder Hilfe benötigen. Bitte zögern Sie nicht uns zu kontaktieren!
                        </p>
                        <Space size={5} desktop/>
                        <Space size={2} mobile/>
                        <span>Baarermatte 1, 6340 Baar</span>
                        <span>Phone: +41 76 442 89 25</span>
                        <span>Email: info@quick-credit.ch</span>


                        <Space size={5} mobile />
                    </Column>

                    <Column justifyContent={'center'} flex={{ desktop: 0.7 }}>
                        <ContentBlock background={'c_white'} >
                            <form ref={ref} onSubmit={event => handleFormSubmit(event)}> 
                            {!applicationSent && !isMessageSending && <>
                                <Row responsive>
                                    <Input type='text' label='Name' id='contact-name' />
                                    <Column flex={{ desktop: 0.025 }} />
                                    <Input type='text' label='Nachname' id='contact-lastname' />
                                </Row>

                                <Row responsive>
                                    <Input type='tel' label='Telefonnummer' id='contact-number' />
                                    <Column flex={{ desktop: 0.025, mobile: 0 }} />
                                    <Input type='email' label='E-Mail' id='contact-email' />
                                </Row>

                                <Row>
                                    <Input type='textarea' label='Nachricht' id='contact-message' />
                                </Row>
                                <Row justifyContent={'center'}>
                                    <Button type={'primary'}>Absenden</Button>
                                </Row>
                                </> }

                                {applicationSent &&
                                    <>
                                        {isMessageSending && <Row justifyContent={'center'}><LoadingOutlined style={{ fontSize: '82px' }} /></Row>}
                                        {!isMessageSending &&
                                            <>
                                                <Row justifyContent={'center'}>
                                                    <Column>
                                                        <h2 style={{ textAlign: 'center', textShadow: 'none' }}><strong>Vielen Dank</strong> für Ihre Nachricht.</h2>
                                                        <p style={{ textAlign: 'center' }}>Ein Kreditexperte wird sich innerhalb der nächsten 24 Stunden bei Ihnen melden.</p>
                                                    </Column>


                                                </Row>
                                            </>
                                        }
                                    </>


                                }

                            </form>
                        </ContentBlock>
                       
                    </Column>
                    
                    
                </Row>
               
                
            </Container>
            
            
        </Section>
    )
}