import { Column } from "../../components/common/Column"
import { Container } from "../../components/common/Container"
import { Row } from "../../components/common/Row"
import { Image } from "../../components/common/Image"
import Video from '../../assets/videos/header.mp4'

import { Calculator } from "../../components/common/Calculator"

export const Header = () => {
    const pathname = window.location.pathname;    
    const obj = {
        '/immobilien-kredit': <h1><b>Immobilien</b> Kredit</h1>,
        '/bildung-kredit': <h1><b>Bildung</b> Kredit</h1>,
        '/renovation-kredit': <h1><b>Renovation</b> Kredit</h1>,
        '/reisen-kredit': <h1><b>Reise</b> Kredit</h1>,
        '/elektronik-kredit': <h1><b>Elektronik</b> Kredit</h1>,
        '/fahrzeuge-kredit': <h1><b>Fahrzeuge</b> Kredit</h1>,
    }

    const picuteObj = {
        '/immobilien-kredit': <Image name='immobilien-bg.webp' className={'header-image'}/>,
        '/bildung-kredit': <Image name='header-bildung.webp' className={'header-image'}/>,
        '/renovation-kredit': <Image name='immobilien-bg.webp' className={'header-image'}/>,
        '/reisen-kredit': <Image name='header-reisen.webp' className={'header-image'}/>,
        '/elektronik-kredit': <Image name='header-elektronik.webp' className={'header-image'}/>,
        '/fahrzeuge-kredit': <Image name='header-fahrzeuge.webp' className={'header-image'}/>,
    }

    return (
        <div id="header">
            <Container>
                <Row responsive>
                    <Column color={'white'} alignItems='flex-start' justifyContent='center'>
                        {obj[pathname]}

                        {pathname === '/' && <h1><b>Schnell</b> und <b>unkompliziert</b> zu Ihrem Kredit!</h1>}
                        
                        
                        <span className="header-slogan">Kostenslos und unverbindlich</span>
                        
                        
                    </Column>
                    <Column alignItems='center' justifyContent='center'>                        
                        <Calculator />
                    </Column>
                </Row>
            </Container>
                                               
            {pathname === '/' &&  <video autoPlay preload='auto' playsInline controls={false} loop muted className='header-video' src={Video} type='video/mp4' />}
            {picuteObj[pathname]}
            <div className="header-background" />

            
        </div>
    )
}